module.exports = {
  title: "兑换",
  description: "通过我们的优惠券，免费住宿等福利奖励自己。",
  label_filter: "过滤    ",
  label_point: "积分",
  label_sperator_point: "to",
  btn_label_view: " 查看 ",
  label_location: "兑换所在国家",
  label_category: "奖励分类",
  label_point_required: "所需点数:  ",
  label_point: " 积分",
  placeholder_location: "全部酒店",
  placeholder_category: "全部类别",
  crumb_home_label: "主页",
  crumb_reward_label: "奖励",
  detail_point_required: "所需点数",
  placeholder_quantity: "请选择",
  label_btn_redeem_now: "现在兑换",
  label_popup_title: "确认兑换",
  text_confirmation: "兑换点数？",
  placeholder_note: "请说明何时想使用该礼卷",
  label_btn_cancel: "取消",
  label_btn_ok: "兑换",
  label_ribbon_special_offer: "特别优惠",
  redemption_unavailable:
    '积分兑换正在更新，将于<span class="!tw-font-bold">2024年10月1日至6日</span>无法使用。',
};
